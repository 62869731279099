
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as TransactionsService from '@/services/DAL/transactionsService';
import * as DebtService from '@/services/DAL/debtService';
import EquityEdit from '@/views/InvestmentTransaction/Equity/EquityEdit.vue';
import ExpenseEdit from '@/views/InvestmentTransaction/Expense/ExpenseEdit.vue';
import CashTransferEdit from '@/views/InvestmentTransaction/Cash/CashTransferEdit.vue';
import CashEdit from '@/views/InvestmentTransaction/Cash/CashEdit.vue';
import CapitalEdit from '@/views/InvestmentTransaction/Capital/CapitalEdit.vue';
import CreditNoteEdit from '@/views/InvestmentTransaction/CreditNote/CreditNoteEdit.vue';
import ElementUI from 'element-ui';

type ConsiderationsType = TransactionsService.ExternalCashMovementConsideration | DebtService.InstrumentTransactions | TransactionsService.Equity | TransactionsService.Cash | TransactionsService.Capital | TransactionsService.CreditNote | TransactionsService.Expense;

@Component({
    components: {
        EquityEdit,
        ExpenseEdit,
        CashEdit,
        CashTransferEdit,
        CapitalEdit,
        CreditNoteEdit
    }
})
export default class ConsiderationEdit extends Vue {
    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    
    //#endregion Private declarations for Services

    //#region Props
    @Prop( {type: Object }) readonly header: TransactionsService.Header;
    @Prop( {type: String }) readonly direction: string;
    @Prop( {type: Object }) readonly considerationType: TransactionsService.ConsiderationTypes;
    @Prop( {type: Number }) readonly considerationTypeId: number;
    @Prop( {type: Object }) readonly consideration: ConsiderationsType;
    @Prop( {type: Object }) readonly transactionType: TransactionsService.Types;
    @Prop( {type: Number }) readonly ownerId: number;
    @Prop( {type: Array }) readonly ownerIdList: Array<number>;
    @Prop( {type: Number }) readonly newTransactionInvestmentId: number;
    // @Prop( {type: Number }) considerationId: number;
    //#endregion Props

    //#region Data
    considerationType_ = {} as TransactionsService.ConsiderationTypes;
    consideration_ = {} as ConsiderationsType;
    header_: TransactionsService.Header;
    direction_: string
    isNew = false;
    //#endregion Data

    //#region Lifecycle
    created() {
        this._transactionsService = new TransactionsService.TransactionsService();
    }
    async mounted() {
        if (this.direction) this.direction_ = this.direction;
        if (this.header && Object.keys(this.header).length){
            this.header_ = this.header;
        }
        if (this.consideration && Object.keys(this.consideration).length){
            this.consideration_ = this.consideration;
            this.direction_ = this.consideration_.ConsiderationDirection;
        }
        else {
            this.isNew = true;
        }

        if (this.considerationType && Object.keys(this.considerationType).length){
            this.considerationType_ = this.considerationType;
        }
        else if ((this.considerationTypeId && (!this.considerationType || !Object.keys(this.considerationType).length)
        || (this.consideration_ && Object.keys(this.consideration_).length && this.consideration_.ConsiderationTypeId))){
            await this.fetchConsiderationType();
        }
        else if (!this.isNew) {
        }
        else if (!this.isNew) {
            this.$alert('Consideration Type is required');
            console.error('Properties ConsiderationType and ConsiderationTypeId are invalid');
        }
    }
    //#endregion Lifecycle
    
    //#region Watches

    //#endregion Watches
    get showCapitalEdit(): boolean {
        if (this.considerationType_ 
            && this.considerationType_.EntityType == 'Capital') {
                return true;
            }
        else {
            return false;
        }
    }
    get showEquityEdit(): boolean {
        if (this.considerationType_ 
            && this.considerationType_.EntityType == 'Equity') {
                return true;
            }
        else {
            return false;
        }
    }
    get showDebtEdit(): boolean {
        if (this.considerationType_ 
            && this.considerationType_.EntityType == 'Debt') {
                return true;
            }
        else {
            return false;
        }
    }
    get showCashEdit(): boolean {
        if (this.considerationType_ 
            && this.considerationType_.EntityType == 'BankAccount' && this.consideration_["IsTransfer"] != true) {
                return true;
            }
        else if ((!this.considerationType_ || Object.keys(this.considerationType_).length == 0) //handle no ConsiderationTypeId
            && this.consideration_ && this.consideration_.ConsiderationType == 'Cash' && this.consideration_["IsTransfer"] != true){
                return true;
            }
        else {
            return false;
        }
    }
    get showCashTransferEdit(): boolean {
        if (this.considerationType_ 
            && this.considerationType_.EntityType == 'BankAccount' && this.consideration_["IsTransfer"] == true) {
                return true;
            }
        else if ((!this.considerationType_ || Object.keys(this.considerationType_).length ==0) //handle no ConsiderationTypeId
            && this.consideration_ && this.consideration_.ConsiderationType == 'Cash' && this.consideration_["IsTransfer"] == true){
                return true;
            }
        else {
            return false;
        }
    }
    get showCreditNoteEdit(): boolean {
        if (this.considerationType_ 
            && 
                (this.considerationType_.EntityType == 'Note'|| this.considerationType_.EntityType == 'PromissoryNote')  //temporarily handle PN as Note
        
            ) {
                return true;
            }
        else {
            return false;
        }
    }
    get showExpenseEdit(): boolean {
        if (this.considerationType_ 
            && this.considerationType_.EntityType == 'Expense') {
                return true;
            }
        else {
            return false;
        }
    }

    //#region Methods
    async fetchConsiderationType(){
        this.considerationType_ = {} as TransactionsService.ConsiderationTypes;
        const params = {} as TransactionsService.GetConsiderationTypesParameters;
        params.ConsiderationTypeId = this.considerationTypeId || (this.consideration_ && Object.keys(this.consideration_).length) ? this.consideration_.ConsiderationTypeId : undefined;
        const considerationTypes = await this._transactionsService.GetConsiderationTypes(params);
        if (considerationTypes.length ==1){
            this.considerationType_ = considerationTypes[0];
        }
    }
    itemClose(){
        this.considerationType_ = {} as TransactionsService.ConsiderationTypes;
        this.consideration_ = {} as ConsiderationsType;
        this.$emit('close');
    }
    itemSavedOnly(consideration: ConsiderationsType){
        this.$emit('savedOnly', consideration);
    }
    itemSaved(consideration: ConsiderationsType){
        // deprecated.  Use itemSavedOnly + itemClose, like Equity does.
        this.$emit('saved', consideration);
        this.considerationType_ = {} as TransactionsService.ConsiderationTypes;
        this.consideration_ = {} as ConsiderationsType;
    }
    itemDeleted(id: number){
        this.$emit('deleted', id);
        this.considerationType_ = {} as TransactionsService.ConsiderationTypes;
        this.consideration_ = {} as ConsiderationsType;
    }
    itemCleared(){
        this.considerationType_ = {} as TransactionsService.ConsiderationTypes;
        this.consideration_ = {} as ConsiderationsType;
        this.$emit('clear');
    }
}
