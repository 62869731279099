
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import FormattedInput from '@/components/form/FormattedInput.vue';
import * as AssetService from '@/services/DAL/assetService';
import * as TransactionsService from '@/services/DAL/transactionsService';
import Common from '@/utils/common';
import InvestmentSelector from '@/components/form/InvestmentSelector.vue';
import formatters from '@/utils/formatters';
import CommitmentAnalysis from '@/views/InvestmentTransaction/Capital/CommitmentAnalysis.vue';
import CapitalCallScheduleSelector from '@/components/form/CapitalCallScheduleSelector.vue';


import { ElForm } from 'element-ui/types/form';
import ElementUI from 'element-ui';

declare var SmartObject: any;
declare function getStoredSecurityLevel(Id: number): number;
declare function tryParseInt(input: any, defaultValue: number): number;

@Component({
    components: {
        FormattedInput,
        InvestmentSelector,
        CommitmentAnalysis,
        CapitalCallScheduleSelector
    }
})
export default class CapitalEdit extends Vue {
    @Ref() readonly frmCapitalEdit!: ElementUI.Form;
    @Ref() readonly refAmount!: ElementUI.Input;

    //#region Private declarations for Services
    private _transactionsService: TransactionsService.TransactionsService;
    private common: Common;
    private formatters: formatters;
    
    //#endregion Private declarations for Services

    //#region Props
    @Prop({required: true}) readonly header: TransactionsService.Header;
    @Prop() readonly considerationType: TransactionsService.ConsiderationTypes;
    @Prop( {type: Object }) readonly transactionType: TransactionsService.Types;
    @Prop() readonly capital: TransactionsService.Capital;
    
    //#endregion Props

    //#region Data
    capital_ = {} as TransactionsService.Capital;
    selectedInvestment = {} as AssetService.InvestmentList;
    securityLevel_: number = null;
    loading = false;
    loadingPrepaid = false;
    isNew = false;
    transactionType_ = {} as TransactionsService.Types;
    newButInvestmentSetExternally: boolean = false;
    capitalType = {} as TransactionsService.CapitalTypes;
    capitalSubTypes = [] as TransactionsService.CapitalSubTypes[];
    commitmentAnalysis = []  as AssetService.CommitmentAnalysis[];
    showCapitalCommitmentAnalysis: boolean = false;
    enableCommitmentAnalysis = false;

    //#endregion Data

    //#region Lifecycle
    async created() {
        this._transactionsService = new TransactionsService.TransactionsService();
        this.formatters = new formatters();
        this.common = new Common();
        this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
            0
        );

    }
    async mounted() {
        this.initialize();
    }
    //#endregion Lifecycle
    
    //#region Watches
    @Watch('capital.InvestmentId')
    async investmentIdChanged(val: number, oldVal: number) {
        this.getCapitalSubTypes();
        this.getCommitmentAnalysis();
    }
    @Watch('capital.CapitalTypeId')
    async capitalTypeIdChanged(val: number, oldVal: number) {
        this.getCapitalSubTypes();
    }
    @Watch('capital_.InvestmentId')
    async investmentId_Changed(val: number, oldVal: number) {
        this.getCapitalSubTypes();
        this.getCommitmentAnalysis();
    }
    @Watch('capital_.CapitalTypeId')
    async capitalTypeId_Changed(val: number, oldVal: number) {
        this.getCapitalSubTypes();
    }

    //#endregion Watches
    get isFormDirty(): boolean {
        return Object.keys((this as any).veeFields).some(
        key => (this as any).veeFields[key].dirty
        );
    }
    get isFormValid(): boolean{
        return !(Object.keys((this as any).veeFields).some(
            key => (this as any).veeFields[key].invalid
        ));
    }
    get investmentSelectorReadOnly(): boolean {
        return (this.isNew && this.newButInvestmentSetExternally);
    }

    get showSubTypeSelector(): boolean { 
        if (this.capitalType){
            return this.capitalType.UseSubType; 
        }
        else return false;
    }
    get commitmentAnalysisLastRow(): AssetService.CommitmentAnalysis {
        if (this.isPrefundSource && this.commitmentAnalysis && this.commitmentAnalysis.length){
            return this.commitmentAnalysis[this.commitmentAnalysis.length-1];
        }
    }
    get availablePrePaid(): number{
        if (this.commitmentAnalysisLastRow){
            return this.commitmentAnalysisLastRow.PrefundBalance;
        }
    }
    get isPrefundSource(): boolean {
        return (this.capitalType && this.capital_.CapitalType == 'Prepaid Consumption');
    }

    get selectedCapitalSubType(): TransactionsService.CapitalSubTypes {
        if (this.capital_ && this.capital_.SubTypeId && this.capitalSubTypes && this.capitalSubTypes.length){
            return this.common.getSelectedArrayItem(
                    this.capitalSubTypes,
                    this.capital_.SubTypeId.toString(),
                    'CapitalSubTypeId'
                    );
        }
        else return {} as TransactionsService.CapitalSubTypes;
    }

    get showCapitalCallScheduleSelector(): boolean {
        if (this.selectedInvestment && this.selectedInvestment.HasCommitment && this.capital_ && this.selectedCapitalSubType.CapitalSubType == 'Capital Call') return true;
        else return false;
    }

    get capitalAmountDisplay(): number {
        if (this.capital_.ConsiderationDirection == 'Source'){
            return 0 - this.capital_.Amount;
        }
        else {
            return this.capital_.Amount;
        }
    }
    set capitalAmountDisplay(value: number){
        if (this.capital_.ConsiderationDirection == 'Source'){
            this.capital_.Amount = 0 - value;
        }
        else {
            this.capital_.Amount = value;
        }
    }
    //#region Methods
   async initialize(){
        if (this.capital && Object.keys(this.capital).length){
            this.capital_ = this.capital;
            this.isNew = !this.capital.Id;
            this.newButInvestmentSetExternally = (this.isNew && !!this.capital_.InvestmentId);
        }
        else {
            this.isNew = true;
            this.capital_ = new TransactionsService.Capital();
            this.$nullifyObjectProps(this.capital_);
            this.capital_.TransactionHeaderId = this.header.Id;
            this.capital_.OwnerId = this.header.BaseOwnerId; // could be empty
            this.capital_.ConsiderationTypeId = this.considerationType ? this.considerationType.Id : undefined;
            this.capital_.ConsiderationType = this.considerationType ? this.considerationType.Name : undefined;
            this.capital_.ConsiderationDirection = this.considerationType ? this.considerationType.Direction : undefined;
            this.newButInvestmentSetExternally = (this.isNew && !!this.capital_.InvestmentId);
        }
        if (!this.capital_.CapitalTypeId){  // "new" passed in Capital object might not, and newly-created object above def will not, have capital Type.  try to get it:
            this.capitalType = await this.getCapitalType();
            this.capital_.CapitalTypeId = this.capitalType.Id;
            this.capital_.CapitalType = this.capitalType.Name;
        }
        else if (this.capital_.CapitalTypeId ){  
            // "new" passed in Capital object from list will have CapitalTypeId, but not name.  Get CapitalTypeById to set name (for display).  
            // even when not new, we need to get Capital Type to evaluate the SubType
            // Use a different function than above so that there is no chance of getting a diffent Type
            this.capitalType = await this.getCapitalTypeByTypeId(this.capital_.CapitalTypeId);
            if (!this.capital_.CapitalType){
                this.capital_.CapitalType = this.capitalType.Name;
            }
        }
        await this.handleTransactionType();

        // need to get TransactionType before thinking about setting the InvestmentId based on the Header.BaseInvestmentId
        if (this.isNew && !this.capital_.InvestmentId && this.header.BaseInvestmentId
            && !(this.transactionType_ && this.transactionType_.IsDistribution && this.capital_.ConsiderationDirection == 'Result')){ // Don't set the Investment to that of the distributing Fund.
            this.capital_.InvestmentId = this.header.BaseInvestmentId;
        }

        if (this.capitalType && this.capitalType.UseSubType) this.getCapitalSubTypes();

        if (this.isPrefundSource) this.getCommitmentAnalysis();

        this.$validator.validate();
        
   }
   async saveItem(){
        if (!this.capital_.CapitalTypeId){
            this.$notify.error('Capital Type is not set (and cannot be set by user).  Please contact Dan.');  //TODO            
        }
        else if (this.isFormValid) {
            this.capital_.ConsiderationDescription = this.selectedInvestment.NameOwnerAccountTypeIdBank;
            if (this.isNew){
                const id = await new SmartObject('InvestmentTransactionCapital').createObject(
                    this.capital_
                );
                this.capital_.CapitalId = id;
                this.capital_.Id = id;
                this.isNew = false;
                this.$notify.success('New Capital Item Added');
            }
            else{
                await new SmartObject('InvestmentTransactionCapital', this.capital_.CapitalId).updateObject(
                    this.capital_
                );
                this.$notify.success('Capital Item Updated');
            }
            this.capital_.ConsiderationValue = this.capital_.Amount;
            this.$emit('saved', this.capital_);
        }
    }
        
    clearItem(){
        this.capital_.Amount = null;
        this.capital_.ConsiderationValue = null;
        this.capital_.InvestmentId = null;
        this.capital_.Comment = null;
        this.capital_.ConsiderationDescription = null;
        this.$emit('clear');
    }

    async deleteItem() {
        if (!this.isNew) {
            try {
                await this.$confirm(
                    'This will permanently delete this Capital item. Continue?',
                    'Warning',
                    {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }
                );

                await this.doDeleteTransactionCapital();
            } catch {}
        }
    }

    async doDeleteTransactionCapital() {
        try {
            await new SmartObject(
                'InvestmentTransactionCapital',
                this.capital_.CapitalId
            ).deleteObject();

            this.$notify.success('Capital item deleted.');

            await new SmartObject('CommentLog').createObject({
                EntityType: 'InvestmentTransactionHeader',
                EntityId: this.capital_.TransactionHeaderId,
                Comment: `Capital ${this.capital_.CapitalId} Deleted.  (${this.capital_.ConsiderationDescription} ${this.$accounting.formatMoney(this.capital_.Amount)})`,
                SystemGenerated: true
            });

            this.$notify.success('Investment Header Comment added.');

            this.$emit('deleted', this.capital_.CapitalId);
            this.capital_ = {} as TransactionsService.Capital;
        } catch {
            this.$notify.error('Something went wrong processing your request, please try again.');
        }
    }

    investmentSelected(investment: AssetService.InvestmentList){
        this.selectedInvestment = investment;
    }
    async handleTransactionType(){
            this.transactionType_ = {} as TransactionsService.Types;
        if (this.transactionType && Object.keys(this.transactionType).length && this.transactionType.Id > 0){
            this.transactionType_ = this.transactionType;
        }
        else if (this.header && Object.keys(this.header).length && this.header.TypeId > 0){ 
            const params = {} as TransactionsService.GetTypesParameters;
            const types = await this._transactionsService.GetTypes(params);
            if (types && types.length){
                this.transactionType_ = this.common.getSelectedArrayItem(
                    types,
                    this.header.TypeId.toString(),
                    'Id'
                    );
            }
        }
    }
    async getCapitalTypeByTypeId(typeId: number): Promise<TransactionsService.CapitalTypes> {
        let ret = {} as TransactionsService.CapitalTypes;
        const params = {} as TransactionsService.GetCapitalTypesParameters;
        params.CapitalTypeId = this.capital_.CapitalTypeId;
        const types = await this._transactionsService.GetCapitalTypes(params);
        if (types.length == 1){
            ret = types[0];
        }
        return ret;
    }
    async getCapitalSubTypes(){
        this.capitalSubTypes = [] as TransactionsService.CapitalSubTypes[];
        if (! (this.capital_.CapitalTypeId && this.capital_.InvestmentId)){
            return;
        }
        const params = {} as TransactionsService.GetCapitalSubTypesParameters;
        params.CapitalTypeId = this.capital_.CapitalTypeId;
        params.InvestmentId = this.capital_.InvestmentId;
        this.capitalSubTypes = await this._transactionsService.GetCapitalSubTypes(params);
        if (this.capitalSubTypes.length == 1 && !this.capital_.SubTypeId){
            this.capital_.SubTypeId = this.capitalSubTypes[0].CapitalSubTypeId;
        }
    }
    fetchedCommitmentAnalysis(data: AssetService.CommitmentAnalysis[]){
        this.commitmentAnalysis = data;
    }
    async getCommitmentAnalysis(){
        this.showCapitalCommitmentAnalysis = true;
        this.enableCommitmentAnalysis = true; // this will trigger fetchedCommitmentAnalysis
        await this.$nextTick();
        this.showCapitalCommitmentAnalysis = false;
    }

async getCapitalType(): Promise<TransactionsService.CapitalTypes> {
        let ret = {} as TransactionsService.CapitalTypes;
        const params = {} as TransactionsService.GetCapitalTypesParameters;
        if (this.considerationType && this.considerationType.EntitySubTypeId){
            params.CapitalTypeId = tryParseInt(this.considerationType.EntitySubTypeId, null);
        }
        else if (this.isNew && this.transactionType && Object.keys(this.transactionType).length && this.considerationType && Object.keys(this.considerationType).length){
            console.error('CapitalTypeId not set by ConsiderationType.EntitySubTypeId.  Logic for setting by Transaction Type has been removed.  if hitting this, implement a solution.');
            // switch (this.transactionType.Name){
            //     case 'Capital Commitment': params.CapitalType = 'Commitment'
            //     break;
            //     case 'Capital Call':
            //         params.CapitalType = (this.considerationType.Direction == 'Source') ? 'Distribution' : 'Call'; // when 'Source', that means it's funding the call, so it's a Dist/Redemption from a fund
            //     break;
            //     case 'Purchase':
            //         params.CapitalType = (this.considerationType.Direction == 'Source') ? 'Distribution' : 'Contribution'; // when 'Source', that means it's funding the purchase, so it's a Dist/Redemption from a fund
            //     break;
            //     case 'Transfer / IPO':
            //         params.CapitalType = (this.considerationType.Direction == 'Source') ? 'Distribution' : 'Contribution'; 
            //     break;
            //     case 'Capitalizable Expense':
            //         params.CapitalType = 'Expense'; 
            //     break;
            //     case 'Return':
            //         params.CapitalType = 'Distribution'; 
            //     break;
            // }
        }
        if ((params.CapitalType && params.CapitalType.length) || params.CapitalTypeId){
            const types = await this._transactionsService.GetCapitalTypes(params);
            if (types.length == 1){
                ret = types[0];
            }
        }
        return ret;
    }

//#endregion Methods
}
