
    import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
    import Common from '@/utils/common';
    import * as TransactionsService from '@/services/DAL/transactionsService';
    import * as DebtService from '@/services/DAL/debtService';
    import * as transactionTypes from '@/views/InvestmentTransaction/transaction';
    import TransactionHeader from '@/views/InvestmentTransaction/InvestmentTransactionNew/TransactionHeader.vue';
    import ConsiderationList from '@/views/InvestmentTransaction/Consideration/ConsiderationList.vue';
    import GeneralLedgerTView from '@/views/GeneralLedger/GeneralLedgerTView.vue';
    import CapitalList from '@/views/InvestmentTransaction/Capital/CapitalList.vue';
    import * as SystemService from '@/services/DAL/systemService';
    import EquityTransaction from '@/views/InvestmentTransaction/Equity/EquityTransaction.vue'
    import StakeTransaction from '@/views/InvestmentTransaction/Funds/StakeTransaction.vue';
    import InstrumentTransactionEdit from '@/views/DebtInstrument/InstrumentTransactionEdit.vue'
    import Distribution from '@/views/InvestmentTransaction/Funds/Distribution.vue';
    import Dividend from '@/views/InvestmentTransaction/Equity/Dividend.vue';
    import Escrow from '@/views/InvestmentTransaction/Equity/Escrow.vue';
    import PDFViewer from '@/components/other/PDFViewer.vue';
    import * as MicrosoftGraphTypes from '@microsoft/microsoft-graph-types';
    import MicrosoftGraphClient, {
    GraphRequest,
    ResponseType,
    Client
    } from '@microsoft/microsoft-graph-client';
    import { MicrosoftGraphService } from '@/services/MicrosoftGraphService';
    import unionBy from 'lodash/unionBy';
import { Header } from "element-ui";


    declare function getStoredSecurityLevel(Id: number): number;
    declare function tryParseInt(input: any, defaultValue: number): number;
    
    type ConsiderationsType = TransactionsService.ExternalCashMovementConsideration | DebtService.InstrumentTransactions | TransactionsService.Equity | TransactionsService.Cash | TransactionsService.Capital | TransactionsService.CreditNote | TransactionsService.Expense;

    @Component({
        components: {
            TransactionHeader,
            ConsiderationList,
            GeneralLedgerTView,
            CapitalList,
            StakeTransaction,
            InstrumentTransactionEdit, 
            EquityTransaction,
            Distribution,
            Dividend,
            Escrow,
            'pdf-viewer': PDFViewer
        }
    })
    export default class NewInvestmentTransaction extends Vue {
        @Ref() refConsiderationListSource!: ConsiderationList;
        @Ref() refConsiderationListResult!: ConsiderationList;
        @Ref() refCapitalCommitment!: CapitalList;
        @Ref() refDistribution!: Distribution;
        @Ref() refStakeTransaction!: StakeTransaction;
        @Ref() refInstrumentTransactionEdit!: typeof InstrumentTransactionEdit;
        @Ref() refEquityTransaction!: EquityTransaction;

    //#region Private declarations for Services
    private _graphService: MicrosoftGraphService;
    private common: Common;
    //#endregion Private declarations for Services

    //#region Props
    @Prop( {type: Number }) readonly newTransactionInvestmentId: number;

    //#endregion Props

    //#region Data
    loading = false;
    showNewItem = true;
    securityLevel_: number = null;
    selectedEquity = {} as TransactionsService.Equity;
    selectedCaptial = {} as TransactionsService.Cash;

    //effectively, the store:
    activeStep: number = 0;
    previousStep: number = 0;
    header = {} as TransactionsService.Header;
    transactionType = {} as TransactionsService.Types;
    transactionSubType = {} as SystemService.NamedKeys;

    // consideration = [] as TransactionsService.Consideration[];
    consideration: Array< ConsiderationsType > = [];

    showSideBySide = false;
    sideBySideFileData = {} as MicrosoftGraphTypes.DriveItem;
    sideBySideFileName: string = null;
    sideBySidePath: string = null; // used to highlight open file in children

    distributionIsBalanced: boolean = false;

    //#endregion Data

    //#region Lifecycle
    created() {
        this.common = new Common();
        this._graphService = new MicrosoftGraphService();
        this.securityLevel_ = tryParseInt(
            getStoredSecurityLevel(this.$namedKey.SecurityView.ManageAssets),
            0
        );
        this.activeStep = 1;
        window.addEventListener("beforeunload", (e) => {
            e.preventDefault();
            // chrome requires returnValue to be set
            const message = "You have unsaved changes. Are you sure you wish to leave?";
            e.returnValue = message
            return message
        })
    }
    //#endregion Lifecycle

    //#region Watches
    @Watch('activeStep')
    async activeStepChanged(val: number, oldVal: number) {
        if (val < oldVal && oldVal > 5 && val != 4){// special forms: back button should return to start.
            this.activeStep = 1;
        }
        // else if (this.transactionType && !this.transactionType.ShowSourceConsideration && val == 2){// hide Source
        //     this.activeStep = (oldVal == 3) ? 1 : 3;
        // }
    }
    //#endregion Watches

    //#region Computed
    get showNext(): boolean {
        // if (this.activeStep == 1 && this.header.Id ) return true; //10/22/21 removing this; if on the header step, only use the "Continue" button from the Header 
        if (this.activeStep == 2 && this.sourceConsiderations.length > 0 ) return true;
        if (this.activeStep == 3 && this.resultConsiderations.length > 0 ) return true;
        if ((this.activeStep == 20 || this.activeStep == 22 || this.activeStep == 23) && this.distributionIsBalanced ) return true; //Dist, Div, Escrow
        if (this.activeStep == 30 ) return true;
        if (this.activeStep == 40 ) return true; 
        if (this.activeStep == 50 ) return true; 
        return false;

    }
    get sourceConsiderations(): Array<ConsiderationsType> {
        return this.consideration.filter(consideration => consideration.ConsiderationDirection == 'Source')
    }
    get resultConsiderations(): Array<ConsiderationsType> {
        return this.consideration.filter(consideration => consideration.ConsiderationDirection == 'Result')
    }
    //#endregion Computed


    //#region Methods
    decrementStep(){
        if (this.activeStep == 4 && this.previousStep){ // Accounting
            this.activeStep = this.previousStep;
        }
        else this.activeStep--;
        
    }
    incrementStep(){
        if (this.activeStep == 20 || this.activeStep == 22 || this.activeStep == 23 || this.activeStep == 30 || this.activeStep == 40 || this.activeStep == 50){ // Dist, Stake
            this.previousStep = this.activeStep;
            this.activeStep = 4; // Accounting
        }
        else {
            this.activeStep++;
            this.previousStep = null;
        }
    }
    async determineNextStep(skipToAccounting: boolean = false){
        this.previousStep = this.activeStep;
        if (skipToAccounting){
            this.activeStep = 2; // Source
            await this.$nextTick();
            this.activeStep = 3; // Result
            await this.$nextTick();
            this.activeStep = 4; // Accounting
        }
        else if (this.transactionType.IsCapitalCommitment){
            this.activeStep = 10;
        }
        else if (this.transactionType.IsDistribution) {
            this.activeStep = 20;
        }
        else if (this.transactionType.Id == 16) { //DIV
            this.activeStep = 22;
        }
        else if (this.transactionType.Id == 17) { //Escrow
            this.activeStep = 23;
        }
        else if (this.transactionType.IsStakeTransaction) {
            this.activeStep = 30;
        }
        else if (this.transactionType.IsShareTransaction) {
            this.activeStep = 40;
        }
        else if (this.transactionType.IsDebtTransaction) {
            this.activeStep = 50;
        }
        else {
            this.activeStep++; // 2
        }
    }
    clearAll(){
        this.header = {} as TransactionsService.Header;
        this.transactionType = {} as TransactionsService.Types;
        this.transactionSubType = {} as SystemService.NamedKeys;
        this.consideration = [] as Array<ConsiderationsType>;
        this.activeStep = 1;
        this.closeSideBySide();

    }
    getSummary(param) {
        const columnList = [2];
        const totalLabel = 'Total';
        const totalLabelIndex = 1;
        const formats = new Map();
        formats.set(2, 'currency');
        const result = this.common.getSummaryArray(param, columnList, totalLabel, totalLabelIndex, formats);
        return result;
    }

    headerSaved(header: TransactionsService.Header, transactionType: TransactionsService.Types, transactionSubType: SystemService.NamedKeys) {
        this.header = header;
        this.transactionType = transactionType;
        this.transactionSubType = transactionSubType;
    }
    considerationSaved(consideration: ConsiderationsType ) {
        const existing: ConsiderationsType = this.common.getSelectedArrayItem(
            this.consideration,
            consideration.Id.toString(),
            'Id'
        );
        if (existing && Object.keys(existing).length){
            const index = this.consideration.indexOf(existing);
            this.consideration.splice(index, 1, consideration);
        }
        else {
            this.consideration.push(consideration);
        }
    }
    considerationDeleted(considerationId: number){
        const existing: ConsiderationsType = this.common.getSelectedArrayItem(
            this.consideration,
            considerationId.toString(),
            'Id'
        );
        if (existing && Object.keys(existing).length){
            const index = this.consideration.indexOf(existing);
            this.consideration.splice(index, 1);
        }
    }
    async pinDocument(payload){
        await this.fetchsideBySideFileData(payload.path);
        
        if (this.sideBySideFileData && !!this.sideBySideFileData['@microsoft.graph.downloadUrl']){
            if (this.showSideBySide){ // clear it first
                this.showSideBySide = false;
                await this.$nextTick();
            }
            this.showSideBySide = true;
            this.sideBySideFileName = this.sideBySideFileData.name;
            this.sideBySidePath = payload.path;
        }
        else {
            this.showSideBySide = false;
        }

    }
    async fetchsideBySideFileData(path: string){
        try {
            const graphClient: MicrosoftGraphClient.Client = await this._graphService.getGraphClient();
            this.sideBySideFileData = await graphClient.api(path).get();
            return this.sideBySideFileData;
        }
        catch (err){
            console.error(err);
        }
    }
    async closeSideBySide(){
        this.showSideBySide = false;
        this.sideBySideFileData = {};
        this.sideBySideFileName = '';
        this.sideBySidePath = '';
    }
    distributionSaved(isBalanced: boolean){
        this.distributionIsBalanced = isBalanced;
    }
    async deletedTransaction(){
        this.showSideBySide = false;
        this.sideBySideFileName = null;
        this.sideBySidePath = null;
        this.header = {} as TransactionsService.Header;
        this.transactionType = {} as TransactionsService.Types;
        this.transactionSubType = {} as SystemService.NamedKeys;
        this.consideration = [] as Array<ConsiderationsType>;
        this.activeStep = 0;
        await this.$nextTick();
        this.activeStep = 1;
    }
    considerationListUpdated(considerations: Array<ConsiderationsType >){
        this.consideration = unionBy(this.consideration, considerations, 'Id');
    }

    //#endregion Methods

    }
